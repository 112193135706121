.page {
    display: flex;
    justify-content: flex-start;
    position: relative;
    height: max-content;
    width: 100%;
}

.container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: max-content;
    padding: 112px 48px 60px;
    min-height: 60px; 
    margin: 0px;
}

.contentWrapper {
    align-self: center;
    min-width: 280px;
    max-width: 1120px;
    height: max-content;
    width: 100%;
    margin: 0px;
}

@media screen and (max-width: 540px) {
    .header1 {
        font-size: 1.5rem;
    }

    .header2 {
        font-size: 1.3rem;
    }

    .container {
        padding: 56px 24px 30px;
    }
}


